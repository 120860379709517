<template>
  <v-container fluid class="ma-0 pa-0">
    <v-row no-gutters>
      <v-col
        cols="12"
        class="pl-3 mb-0 pb-0"
      >
        <v-tabs
          class="simulacao-tabs"
          active-class="activeMyClass"
          background-color="transparent"
          center-active
          v-model="tabIndex"
        >
          <v-tab
            v-for="(item, index) in menu"
            :key="index"
            @click="rotaMenuSimulacao(item.to)"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row style="min-height: calc(100% - 50px)">
      <v-col cols="12" class="mt-0 pt-0">
        <template>
          <router-view></router-view>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tabIndex : 0,
      menu: [
        {
          title: "Ativações",
          to: "planejamento_ativacao"
        },
        {
          title: "Áreas",
          to: "planejamento_areas"
        },
        {
          title: "Categorias",
          to: "planejamento_categoria"
        },
        {
          title: "Termos",
          to: "biblioteca_termos"
        }
      ]
    };
  },
	created() {  
    this.tabIndex = this.menu.findIndex(item => item.to == this.$route.name)

    if(this.tabIndex != -1) {
      return this.rotaMenuSimulacao(this.$route.name);
    }
    this.tabIndex = 0;
			this.rotaMenuSimulacao(this.menu[0].to);
		
	},
  methods: {
    goTo(rota) {
      this.$router.push({ name: rota });
    },
    rotaMenuSimulacao(rota) {
      if (this.$route.name != rota) {
        this.$router.push({
          name: rota
        });
      }
    }
  },

};
</script>

<style>
th {
  font-weight: normal;
  font-size: 0.9rem !important;
}

.simulacao-tabs .activeMyClass:hover:before {
  background: none;
}
.simulacao-tabs .v-tab:before {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.simulacao-tabs .v-tabs-slider {
  width: 70%;
  margin: 0 15%;
}
.simulacao-tabs .v-tab {
  width: 150px;
  font-size: 1.2rem;
  font-weight: normal !important;
  letter-spacing: 0px;
  color: #999 !important;
  text-transform: none !important;
}
.simulacao-tabs .activeMyClass {
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #000 !important;
}
</style>
